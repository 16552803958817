<template>
	<f-card>
		<v-data-table :headers="headers" :items="inventoryLogs" @click:row="view">
			<template v-slot:[`item.distributor`]="{ item }">
				<div>{{ item.distributor }}</div>
				<div v-if="item.edited" class="pos-edited-tag">
					{{ $t("labels.editedItem") }}
				</div>
			</template>
			<template v-slot:[`item.date`]="{ item }" class="pos-edited-labels">
				<div>{{ item.date }}</div>
				<div v-if="item.edited" class="pos-edited-tag">
					{{ $t("labels.editedItem") }}
				</div>
			</template>
		</v-data-table>
	</f-card>
</template>

<script>
import DateFormater from "../../helpers/date_formater";

export default {
	data: () => ({
		// headers: [
		//     {
		//         text: 'Mês',
		//         align: 'start',
		//         sortable: true,
		//         value: 'date',
		//     },
		//     {
		//         text: 'Total',
		//         value: 'total',
		//         align: 'end'
		//     },
		// ]
	}),
	computed: {
		headers() {
			if (this.isAdmin) {
				return [
					{
						text: this.$t("labels.distributor"),
						align: "start",
						sortable: true,
						value: "distributor",
					},
					{
						text: this.$t("labels.month"),
						align: "start",
						sortable: true,
						value: "date",
					},
					{
						text: this.$t("labels.file"),
						align: "start",
						sortable: true,
						value: "file",
					},
					{
						text: this.$t("labels.createdAt"),
						value: "created",
						align: "end",
					},
				];
			} else {
				return [
					{
						text: this.$t("labels.month"),
						align: "start",
						sortable: true,
						value: "date",
					},
					{
						text: this.$t("labels.file"),
						align: "start",
						sortable: true,
						value: "file",
					},
					{
						text: this.$t("labels.createdAt"),
						value: "created",
						align: "end",
					},
				];
			}
		},
		inventoryLogs() {
			var preMonth;
			return this.$store.state.inventory.list.map((item) => {
				preMonth = DateFormater.monthNumberToString(item.month);
				item["date"] = `${preMonth}/${item.year}`;
				item["created"] = DateFormater.fromDB(item.created_at);
				return item;
				// return {
				//     id: item.id,
				//     date:
				//     total: numberFormater.money( item.total, { decimalLength: 2 } )
				// }
			});
		},
		isAdmin() {
			var isAdmin = true;

			if (this.$store.state.auth.user.distributorID) {
				return false;
			}

			return isAdmin;
		},
	},
	created() {
		this.getData();
	},
	methods: {
		async getData() {
			this.$store.dispatch("inventory/list");
		},
		view(data) {
			// console.log( 'view', data )
			this.$router.push({
				name: "inventory-view",
				params: {
					id: data.id,
				},
			});
		},
	},
};
</script>

<style lang="scss">
.pos-edited-tag {
	color: rgb(122, 122, 122);
	font-size: 0.75rem;
	margin-top: -5px;
}
</style>