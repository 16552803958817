<template>
  <div>
    <f-default-header :title="$t('pages.inventory.title')" :subtitle="$t('pages.inventory.subtitle')" />

    <list-filter-vue store="inventory" @change="getData()" />
    <f-list />

    <f-actions-btn-holder>
      <v-btn color="primary" elevation="0" large @click="create">
        {{ $t('labels.btnNewSend') }}
      </v-btn>
    </f-actions-btn-holder>
  </div>
</template>

<script>
import FList from "../../components/inventory/List.vue";
import ListFilterVue from "../../components/shared/ListFilter";

export default {
  components: {
    FList,
    ListFilterVue,
  },
  methods: {
    create() {
      this.$router.push({
        name: "inventory-upload",
      });
    },
    async getData() {
      this.$store.dispatch("inventory/list");
    },
  },
};
</script>
